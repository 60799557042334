



















import { Vue, Component } from "vue-property-decorator";
import { captureSentryException } from "@/plugins/sentry";
import { AxiosError, AxiosResponse } from "axios";
import DashboardChartApi from "@/services/api/dashboard-charts";
import TimeSeriesLineChartData from "@/value-objects/time-series-line-chart-data";
import LineChart from "@/components/LineChart.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ChartSubtitle from "@/components/ChartSubtitle.vue";
import { ApiResponse } from "@/services/api/axios";

@Component({
    components: { ChartSubtitle, LoadingSpinner, LineChart },
})
export default class AdminDashboardNorthStar extends Vue {
    chartIsLoading: boolean = true;
    lineChartData: TimeSeriesLineChartData | null = null;

    emitErrorNotification(error: Error) {
        captureSentryException(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.emitErrorNotification(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }

    get value(): number {
        if (!this.lineChartData || !this.lineChartData.lastEntry?.value) {
            return 0;
        }
        return this.lineChartData.lastEntry?.value / 30;
    }

    get buffer(): number {
        if (
            !this.lineChartData ||
            !this.lineChartData.secondToLastEntry?.value
        ) {
            return 0;
        }
        return this.lineChartData.secondToLastEntry?.value / 30;
    }

    mounted() {
        DashboardChartApi.show(
            this.$constants.DASHBOARD_CHART_IDS.DSTV_TOTAL_ORDERS
        )
            .then((response: AxiosResponse) => {
                this.lineChartData = new TimeSeriesLineChartData(
                    response.data.data
                );
                this.chartIsLoading = false;
            })
            .catch(this.handleAxiosError);
    }
}
