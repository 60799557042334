import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('admin-dashboard-north-star'),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":50,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS
                            .DSTV_NEW_CUSTOMER_ORDERS}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":100,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS.DSTV_TOTAL_ORDERS}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":2,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS.INCOMPLETE_ORDERS}})],1),(_vm.userIsAdmin)?_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('total-payments-chart')],1):_vm._e(),(_vm.userIsAdmin)?_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":4000000,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS.TOTAL_EXPENSES}})],1):_vm._e(),(_vm.userIsAdmin)?_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":500000,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS.TOTAL_PROFITS}})],1):_vm._e(),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":50,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS.WHATSAPP_ORDERS}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('failed-payments-chart')],1),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('test-orders-chart')],1),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":5,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS
                            .AIRTIME_NEW_CUSTOMER_ORDERS}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":50,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS.AIRTIME_TOTAL_ORDERS}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":10,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS
                            .ENEO_PREPAY_TOTAL_ORDERS}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":10,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS
                            .ENEO_POSTPAY_TOTAL_ORDERS}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":10,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS.CAMWATER_TOTAL_ORDERS}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":100,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS.EXCHANGE_TOTAL_ORDERS}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":5,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS
                            .ENEO_PREPAY_NEW_CUSTOMER_ORDERS}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":5,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS.REFERRAL_ORDERS}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":20,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS.REFUNDED_PAYMENTS}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('admin-dashboard-chart',{attrs:{"y-axis-step":5,"chart-id":_vm.$constants.DASHBOARD_CHART_IDS
                            .CANAL_PLUS_TOTAL_ORDERS}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }